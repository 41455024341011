import type { SearchSuggestionsMode } from '@ui/components/UiSearchSuggestions/UiSearchSuggestions.types'
import { useDebounceFn } from '@vueuse/core'
import type UiPopoverTooltip from '@ui/components/UiPopoverTooltip/UiPopoverToltip.vue'
import { RawlplugUiMqKey } from '@ui/plugin'
import type { RawlplugUiMq } from '@ui/plugin'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import { useAppNav } from '@base/components/AppNav/useAppNav'
import { SearchCharactersLength } from '@ecom/components/AppSearch/AppSearch.types'
import useProductAlias from '@ecom/composables/product/useProductAlias'
import { useProductQueries } from '@ecom/composables/product/useProductQueries/useProductQueries'
import { SearchBarFilterName, SearchBarOptions, SearchUrlPart } from '@ecom/stores/search'

import type { SearchSuggestionsProductsQueryProductsItems } from '@ecom/types/types'
import { useFilters } from '@ecom/composables/useFilters/useFilters'
import type { SearchSuggestionsProductsQuery, SearchSuggestionsProductsQueryVariables } from '#gql'
import { FilterMatchTypeEnum } from '#gql/default'

export function useSearchSuggestions() {
  const { isMobile } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}

  const { t } = useI18n()
  const { toggleDrawer } = useDrawer()
  const { getSearchBarFilters } = useFilters()

  const loading = ref(false)
  const searchSuggestionsCurrentMode = ref<SearchSuggestionsMode>('results')
  const searchSuggestionsResults = useState<SearchSuggestionsProductsQuery['products']>('searchSuggestionsResults', () => null)
  const searchPhraseModel = ref('')
  const areSearchSuggestionsVisible = ref(false)
  const searchSuggesterEl = ref(null)
  const searchSuggestionsPopoverEl = ref<InstanceType<typeof UiPopoverTooltip> | null>(null)

  const _getDefaultSearchSuggestionsProductsParams = (params: SearchSuggestionsProductsQueryVariables, includeSearchPhrase = true) => {
    return {
      search: includeSearchPhrase ? (params?.search ?? '') : '',
      currentPage: 1,
      pageSize: params?.pageSize || 6,
      filters: {
        type_id: {
          in: ['simple'],
        },
      },
    }
  }

  const searchSuggestionsItems = computed(
    () => (searchSuggestionsResults.value?.items ?? []) satisfies SearchSuggestionsProductsQueryProductsItems,
  )

  const getSearchPhraseCategories = (limit?: number) => {
    if (!searchSuggestionsItems.value?.length) {
      return []
    }

    const { categoryLink } = useAppNav()

    const productsCategories: { name: string, canonical_url: string }[] = []

    searchSuggestionsItems.value.forEach((item) => {
      if (!Array.isArray(item?.categories) || !item?.categories?.length) {
        return
      }

      productsCategories.push(...item.categories)
    })

    const uniqueCategories = productsCategories
      .filter((obj, index, originalArray) => {
        return (
          originalArray.findIndex(
            item => item.canonical_url === obj.canonical_url,
          ) === index
        )
      })
      .map(item => ({
        link: categoryLink(item.canonical_url),
        label: item.name,
      }))

    if (!limit || typeof limit !== 'number') {
      return uniqueCategories
    }

    return uniqueCategories.filter((_, index) => index + 1 <= limit)
  }

  const getSearchPopularTerms = (limit?: number) => {
    const popularTermsHolder
      = searchSuggestionsResults.value?.popular_terms ?? []
    if (!popularTermsHolder.length) {
      return []
    }

    const { localePath } = useT3Utils()

    const popularTerms = popularTermsHolder.map(term => ({
      label: term?.search ?? '',
      link: {
        path: localePath(
          `/search/${SearchUrlPart.STORE}`,
        ),
        query: {
          term: encodeURIComponent(
            term?.search ?? '',
          ),
        },
      },
    }))

    if (!limit || typeof limit !== 'number') {
      return popularTerms
    }

    return popularTerms.filter((_, index) => index + 1 <= limit)
  }

  const initSearchSuggestions = useDebounceFn(
    async (params: SearchSuggestionsProductsQueryVariables, selectedSearchOption: SearchBarOptions) => {
      if (!params?.search || params?.search?.length < 3) {
        return null
      }

      const { searchSuggestionsProducts } = useProductQueries()

      const attributeOptions = { match: params?.search ?? '', match_type: FilterMatchTypeEnum.PARTIAL }
      const selectedOptionFilter = {}
      const mergedParams = _getDefaultSearchSuggestionsProductsParams(params)

      switch (selectedSearchOption) {
        case SearchBarOptions.NAME: {
          Object.assign(selectedOptionFilter, { [SearchBarFilterName.name]: attributeOptions })
          Object.assign(mergedParams, _getDefaultSearchSuggestionsProductsParams(params, false))
          break
        }

        case SearchBarOptions.SKU: {
          Object.assign(selectedOptionFilter, { [SearchBarFilterName.sku]: attributeOptions })
          Object.assign(mergedParams, _getDefaultSearchSuggestionsProductsParams(params, false))
          break
        }

        case SearchBarOptions.EAN: {
          Object.assign(selectedOptionFilter, { [SearchBarFilterName.ean]: attributeOptions })
          Object.assign(mergedParams, _getDefaultSearchSuggestionsProductsParams(params, false))
          break
        }

        case SearchBarOptions.VERSUS: {
          Object.assign(selectedOptionFilter, { [SearchBarFilterName.versus]: attributeOptions })
          Object.assign(mergedParams, _getDefaultSearchSuggestionsProductsParams(params, false))
          break
        }
      }

      const searchBarFilters = getSearchBarFilters(selectedOptionFilter)
      Object.assign(mergedParams.filters, searchBarFilters)

      loading.value = true

      try {
        const { products } = (await searchSuggestionsProducts(mergedParams)) || {}

        if (products) {
          searchSuggestionsResults.value = products
        }

        return products
      }
      finally {
        loading.value = false
      }
    },
    500,
  )

  const labels = computed(() => {
    const moreProducts = t('more_products')
    switch (searchSuggestionsCurrentMode.value) {
      case 'init': {
        return {
          leftColumnTitle: t('recommended_for_you'),
          searchPhraseSuggestionsTitle: t('recently_searched'),
          moreProducts,
        }
      }
      case 'results': {
        return {
          leftColumnTitle: '',
          searchPhraseSuggestionsTitle: t('most_searched'),
          searchPhraseCategoriesTitle: t('products_categories'),
          moreProducts,
        }
      }
    }
  })

  const trimPhrase = (phrase?: string) => {
    return phrase?.trim().toLocaleLowerCase()
  }

  /**
   * Finds the exact product and a product whose SKU starts with the exact
   * product's SKU, based on the search phrase and product suggestions.
   *
   * @param {string} searchPhrase - The user-entered search phrase.
   * @param {SearchSuggestionsProductsQueryProductsItems} searchSuggestionsItems
   *
   * Initially, the function searches for an exact product by comparing the
   * trimmed versions of the product's SKU and alias with the trimmed search
   * phrase.
   *
   * If an exact match is found, it then searches for another product whose
   * SKU or alias starts with the SKU of the exact product but is not equal to it.
   *
   * Returns an object containing the exact product and the product with the
   * starting SKU match, if found.
   */
  const findExactProductBySuggestions = (searchPhrase: string, searchSuggestionsItems: SearchSuggestionsProductsQueryProductsItems) => {
    const exactProduct = searchSuggestionsItems?.find(
      (product) => {
        if (!product?.sku) {
          return false
        }

        const sku = product.sku
        const alias = useProductAlias(product).value ?? ''
        return (trimPhrase(sku) === trimPhrase(searchPhrase)) || (trimPhrase(alias) === trimPhrase(searchPhrase))
      },
    )

    const productStartsWithSku = searchSuggestionsItems?.find((product) => {
      if (!product?.sku || !exactProduct?.sku) {
        return false
      }

      const exactSku = exactProduct.sku
      const productSku = product.sku
      const alias = useProductAlias(product).value ?? ''

      return (productSku.startsWith(exactSku) && productSku !== exactSku) || (alias.startsWith(exactSku) && productSku !== alias)
    })

    return {
      exactProduct,
      productStartsWithSku,
    }
  }

  function handleShowSearchSuggestions(callback?: () => void) {
    if (searchPhraseModel.value.length < SearchCharactersLength.MIN) {
      return
    }

    if (callback && callback instanceof Function) {
      callback()
    }
    areSearchSuggestionsVisible.value = true
  }

  function handleSearchInputSelect() {
    if (
      !searchSuggestionsPopoverEl.value
      || searchPhraseModel.value.length < SearchCharactersLength.MIN
    ) {
      return
    }
    searchSuggestionsPopoverEl.value?.activate()
  }

  function closeSearchSuggestions(callback?: () => void) {
    searchSuggestionsPopoverEl.value?.deactivate()
    areSearchSuggestionsVisible.value = false

    if (isMobile.value) {
      toggleDrawer({ open: false })
    }

    if (callback && callback instanceof Function) {
      callback()
    }
  }

  return {
    loading,
    searchSuggestionsCurrentMode,
    labels,
    initSearchSuggestions,
    searchSuggestionsResults,
    searchSuggestionsItems,
    getSearchPhraseCategories,
    getSearchPopularTerms,
    findExactProductBySuggestions,
    searchPhraseModel,
    areSearchSuggestionsVisible,
    searchSuggestionsPopoverEl,
    searchSuggesterEl,
    handleShowSearchSuggestions,
    closeSearchSuggestions,
    handleSearchInputSelect,
  }
}
